import { ItemDTO } from "./ItemsDTO";

const emptyItem: ItemDTO = {
  id: 0,
  source: null,
  name: null,
  description: "",
  availabilityType: null,
  weight: null,
  price: null,
  itemBaseEffectList: null,
  //creatureRewardList: null,
  //sourceId: 0,
  itemType: 0,
  accuracy: null,
  damage: null,
  reliability: null,
  grip: null,
  distance: null,
  stealthType: null,
  amountOfEnhancements: null,
  isAmmunition: null,
  //skillId: null,
  type: 1,
  complexity: null,
  timeSpend: null,
  additionalPayment: null,
  formulaSubstanceList: null,
  blueprintComponentList: null,
  whereToFind: null,
  amount: null,
  isAlchemical: false,
  substanceType: 0,
  stiffness: null,
  skill: null,
  equipmentType: null,
  itemOriginType: null,
  imageFileName: "",
};

export { emptyItem };
